import React, { useEffect, useState } from "react";
import MainLayout from "../../Layout/MainLayout";
import { GROUPID } from "../../Services/Api";
import { FormContact } from "../Contacts";
import { NewsPage } from "../News";
import { TopProduct } from "../Products";
import { Product } from "../Products";
import { Banner } from "../Template/Banner";
import { Sub_Footer } from "../Template/Sub_Footer";

export const Home = () => {
    const [DataMenu, setDataMenu] = useState([]);
    useEffect(() => {
        setTimeout(() => Shop_spWeb_Menu_List(), 1000);
    }, []);

    //#region danh sch menu động
    const Shop_spWeb_Menu_List = async () => {
        const infor = localStorage.getItem("Web_Infor_ListMenu_" + GROUPID)
        if (infor !== undefined && infor !== null && infor !== "") {
            const result2 = JSON.parse(infor);
            setDataMenu(result2)
        }
    };
    //#endregion

    return (
        <MainLayout>
            {/*   <SEO
                title='C.ty TNHH dịch vụ vận chuyển Sài Gòn 247'
                description="Chuyển nhà trọn gói, chuyển văn phòng trọn gói, chuyển kho xưởng trọn gói, chuyển trọ sinh viên giá rẻ, nhận kí gửi cho thuê kho xưởng... "
                name="C.ty TNHH dịch vụ vận chuyển Sài Gòn 247"
            />  */}
            <main>
                <Banner></Banner>
                {
                    DataMenu.length > 0 &&
                        DataMenu.filter(e => e.MenuUrl === "/san-pham").length > 0 ?
                        <Product
                            KeyIsHot={1}
                            KeyTitle={0}
                        /> : <></>
                }
                <NewsPage KeyIsHome={1}/>

            </main>
        </MainLayout>
    )
}
