import React from "react";
import { Helmet } from "react-helmet";

export const Helmet_index = () => {
    return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>CÔNG TY TNHH TRUYỀN THÔNG VÀ DU LỊCH THẾ GIỚI XANH</title>
                {/* <meta name="description" content="Chuyển nhà trọn gói, chuyển văn phòng trọn gói, chuyển kho xưởng trọn gói, chuyển trọ sinh viên giá rẻ, nhận kí gửi cho thuê kho xưởng... " />
                <meta property="og:image" content="Chuyển nhà trọn gói, chuyển văn phòng trọn gói, chuyển kho xưởng trọn gói, chuyển trọ sinh viên giá rẻ, nhận kí gửi cho thuê kho xưởng... " />
                <meta property="og:title" content="Chuyển nhà trọn gói, chuyển văn phòng trọn gói, chuyển kho xưởng trọn gói, chuyển trọ sinh viên giá rẻ, nhận kí gửi cho thuê kho xưởng... " />
                <meta property="og:description" content="Chuyển nhà trọn gói, chuyển văn phòng trọn gói, chuyển kho xưởng trọn gói, chuyển trọ sinh viên giá rẻ, nhận kí gửi cho thuê kho xưởng... " /> */}
            </Helmet>
    );
};
