import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Home } from "../Component/Main";
import { Product, ProductDetail } from "../Component/Products";
import { Footer, Header, Nav, NotFound, Social } from "../Component/Template";
import { ContactPage } from "../Component/Contacts";
import { NewsPage, NewsDetailPage } from "../Component/News";
export const Routers = () => {
  return (
    <BrowserRouter>
      <Route path="/" component={Header} />
       <Route path="/" component={Nav} />
      <Switch>
        <Route path="/lien-he" component={ContactPage} />
        <Route exact path="/san-pham" component={Product} />
        <Route exact path="/chi-tiet-san-pham/:ProductId" component={ProductDetail} />
        <Route exact path="/tin-tuc" component={NewsPage} />
        <Route exact path="/chi-tiet-tin-tuc/:NewsId" component={NewsDetailPage} />
        <Route exact path="/" component={Home} />
        <Route component={NotFound} />
      </Switch>
      <Route path="/" component={Footer} />
      <Route path="/" component={Social} />
    </BrowserRouter>
  );
};
