import React from "react";
import { FormContact } from "../Contacts";
export const ContactPage = () => {
    return (
        <>
            <section>
                <FormContact
                    KeyMap={1}
                    KeyTitle={1}
                />
            </section>
        </>
    );
};
