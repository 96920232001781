import ReactHtml from 'raw-html-react';
import React, { useEffect, useState } from "react";
import ModalImage from "react-modal-image";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { GROUPID } from "../../Services/Api";
import { mainAction } from "../../Redux/Actions";
import { LINK_IMAGE } from "../../Services";
import { Alertsuccess, FormatNumber, ScrollTop } from "../../Utils";
import I18n from 'i18n-js';
import $ from 'jquery'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
export const ProductDetail = () => {
    const { ProductId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [ListData, setListData] = useState([]);
    const [ListDatasuggest, setListDatasuggest] = useState([]);
    useEffect(() => {
        Shop_spWeb_Products_List();
        ScrollTop()
    }, [ProductId]);

    //#region List
    const Shop_spWeb_Products_List = async () => {
        try {
            const pr = {
                Domain: '',
                GroupId: GROUPID
            };
            const params = {
                Json: JSON.stringify(pr),
                func: "Shop_spWeb_Products_List",
            };
            const result = await mainAction.API_spCallServer(params, dispatch);
            if (result?.length > 0) {
                setListData(result.find(e => e.Url === ProductId));
                setListDatasuggest(result.filter(e => e.Url !== ProductId))
            }
        } catch (err) { }
    };
    //#endregion


    const AddToCart = (item) => {

        let cart = JSON.parse(localStorage.getItem("MyCart")) || [];
        // Tìm kiếm sản phẩm trong giỏ hàng
        const existingProductIndex = cart.findIndex(
            (cartItem) => (cartItem.ProductId === item.ProductId));
        if (existingProductIndex !== -1) {
            // Tăng NumberProduct lên 1 nếu sản phẩm đã tồn tại trong giỏ hàng
            cart[existingProductIndex].NumberProduct += 1;
        } else {
            // Thêm sản phẩm mới vào giỏ hàng với NumberProduct ban đầu là 1
            cart.push({ ...item, NumberProduct: 1 });
        }
        localStorage.setItem("MyCart", JSON.stringify(cart));
        $("#totalCart").text(cart.length);
    };
    return (
        <>
            <section class="product-section" id="section_12">
                <div className="bg_head">
                    <Link to="/"><span className="color-white"> {I18n.t("Header.HomePage")}</span></Link>
                    <i class="bi bi-chevron-double-right"></i>
                    <Link className="color-white" to="/san-pham">DỊCH VỤ TOUR</Link>
                    <i class="bi bi-chevron-double-right"></i>
                    <span>{ListData[0]?.ProductName}</span>
                </div>
            </section>
            <section className="section-padding" id="section_12">
                <div className="container mt-5 mb-5">
                    <div className="row">
                        <div className="col-md-6 w3-animate-bottom ">
                            <div className="news-block">
                                <div className="news-block-top">
                                    {ListData.ImageProduct !== undefined &&
                                        (ListData?.ImageProduct).split(",").map((e, key) => {
                                            if (e !== "" && key === 0)
                                                return (
                                                    <img
                                                        src={LINK_IMAGE + e}
                                                        className="news-image"
                                                        style={{ width: "100%" }}
                                                        alt="Product Image"
                                                    />
                                                );
                                        })}
                                </div>
                                <div class="line-iamge">
                                    {ListData.ImageProduct !== undefined &&
                                        (ListData?.ImageProduct).split(",").map((e, key) => {
                                            if (e !== "")
                                                return (
                                                    <div class="image">
                                                        <div class="image-item">
                                                            <ModalImage
                                                                small={LINK_IMAGE + e}
                                                                medium={LINK_IMAGE + e}
                                                                large={LINK_IMAGE + e}
                                                                hideDownload={true}
                                                                hideZoom={true}
                                                                showRotate={true}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                        })}
                                </div>
                                <div className="news-block-info">
                                    <div className="news-block-title mb-2">
                                        {ListData?.Details && (
                                            <div className="card-detail-product ">
                                                <h5 className="m-0 text-center">{I18n.t("Other.DetailProduct")}</h5>
                                                <hr />
                                                {ListData?.Details &&
                                                    ListData?.Details.map((e) => {
                                                        return (
                                                            <div className="row">
                                                                <div className=" col-4 ">
                                                                    <p
                                                                        className="ml-2"
                                                                        style={{ marginLeft: "20px" }}
                                                                    >
                                                                        <strong>{e.DetailName} :</strong>
                                                                    </p>
                                                                </div>
                                                                <div className=" col-8 ">
                                                                    <p className="">{e.DetailDescription}</p>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        )}
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-6  w3-animate-bottom">
                            <h5 className="fs-1 ">{ListData?.ProductName}</h5>
                            <hr />
                            <div className="news-block">
                                <div class={"custom-block-body text-center pointer w3-animate-bottom"}>
                                    <div style={{ fontSize: '15px' }} className="text-black">
                                        <div class="d-flex mb-3">
                                            <i class="fa-solid fa-sack-dollar me-3 mt-1 layoutcolor"></i>
                                            <span className='layoutcolor carousel-name-title'>Trọn gói : {FormatNumber(ListData?.ProductPrice)} <strong>đ</strong></span>
                                        </div>
                                        <div class="d-flex mb-3 ">
                                            <i class="fa-solid fa-map-location me-3 mt-1 layoutcolor"></i>
                                            <span className='carousel-name-title'> {ListData?.Attributes?.find(a => a.AttributeId === 17)?.AttributeName} : {ListData?.Attributes?.find(a => a.AttributeId === 17)?.Description}</span>
                                        </div>
                                        <div class="d-flex mb-3 carousel-name-title">
                                            <i class="fa-solid fa-clock me-3 mt-1 layoutcolor"></i>
                                            <span className='carousel-name-title'>{ListData?.Attributes?.find(a => a.AttributeId === 18)?.AttributeName}  : {ListData?.Attributes?.find(a => a.AttributeId === 18)?.Description} </span>
                                        </div>
                                        <div class="d-flex mb-3 carousel-name-title">
                                            <i class="fa-solid fa-map-pin me-4 mt-1 layoutcolor"></i>
                                            <span>{ListData?.Attributes?.find(a => a.AttributeId === 19)?.AttributeName} : {ListData?.Attributes?.find(a => a.AttributeId === 19)?.Description}</span>
                                        </div>
                                        <div class="d-flex mb-3 carousel-name-title">
                                            <i class="fa-solid fa-calendar me-3 mt-1 layoutcolor"></i>
                                            <span>{ListData?.Attributes?.find(a => a.AttributeId === 21)?.AttributeName} : {ListData?.Attributes?.find(a => a.AttributeId === 21)?.Description}</span>
                                        </div>

                                    </div>
                                    <Link className="color-white" to="/lien-he"><button type="button" class="custom-btn btn smoothscroll" style={{ width: '100%' }}>Chat với nhân viên</button>
                                    </Link>

                                </div>
                            </div>


                        </div>
                        <div className='row col-md-12'>
                            <div className="news-block-title mb-2">
                                <h5>{I18n.t("Other.ProductionDescribtion")}</h5>
                            </div>
                            <div className="news-block-body">
                                {ListData?.ProductDesciption !== undefined && (
                                    <ReactHtml html={ListData?.ProductDesciption} componentMap={{ ProductDetail }} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <h5 class="mt-5 mb-3">{I18n.t("Service.OtherServices")}</h5>
                        {ListDatasuggest.length > 0
                            &&
                            ListDatasuggest.slice(0, 3).map(item => {
                                return (
                                    <div className="col-lg-4 p-3">
                                        <div class="news-block bor-10 row  mt-4 bor-b-grey card-hover fixservice w3-animate-left"
                                            key={item.ProductId}
                                            onClick={() => {
                                                history.push(`/chi-tiet-san-pham/${item.Url}`);
                                                window.scrollTo({
                                                    top: 80,
                                                    behavior: "smooth"
                                                });
                                            }}
                                        >
                                            <div class="col-md-5 w3-animate-left">
                                                <img src={item.Image} class="news-image img-70 " alt="" />
                                            </div>
                                            <div class="col-md-7 bor-l mt-30xs">
                                                <div class="news-block-title">
                                                    <h6
                                                        class="news-block-title-link fz-14 upper cl-grey">{item.ProductName}
                                                    </h6>
                                                    <span className="spanServicedt"
                                                    > {item.ProductNote}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </section>
        </ >
    );
};
