import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FormatMoney } from "../../Utils"
import { Paging, SelectCategory } from "../../Common";
import MainLayout from "../../Layout/MainLayout";
import { mainAction } from "../../Redux/Actions";
import { GROUPID, LINK_IMAGE } from "../../Services";
import { Alerterror, Alertsuccess, Alertwarning, FormatNumber } from "../../Utils";
import I18n from "i18n-js";
import $ from 'jquery'
export const Product = ({
    KeyTitle = 0,
    KeyIsHot = 0
}) => {
    useEffect(() => {
        Shop_spProducts_List();
    }, []);

    const dispatch = useDispatch();
    const [ListData, setListData] = useState([]);
    const [NumberShowHome, setNumberShowHome] = useState(6);
    const infor = localStorage.getItem("Web_Products_" + GROUPID)
    const Infor_Setting = localStorage.getItem("Web_Infor_Setting_" + GROUPID)

    const Shop_spProducts_List = async () => {
        try {
            debugger
            if (infor !== undefined && infor !== null && infor !== "") {
                setListData(JSON.parse(infor));
            }
            if (Infor_Setting !== undefined && Infor_Setting !== null && Infor_Setting !== "") {
                setNumberShowHome(JSON.parse(Infor_Setting).find(a => a.KeySetting === 'NumberShowHome').DataSetting);
            }
            const params = {
                Json: JSON.stringify({ "Domain": "", "GroupId": GROUPID }),
                func: "Shop_spWeb_Products_List",
            };
            const result = await mainAction.API_spCallServer(params, dispatch);

            if (result.length > 0) {
                //từ home thì lấy is hot
                let ar = KeyIsHot === 1 ? result.filter(a => a.IsHot === true) : result
                setListData(ar)
                localStorage.setItem("Web_Products_" + GROUPID, JSON.stringify(result));
            }
        } catch (err) {
            // Xử lý lỗi
        }
    };

    return (
        <>
            <section class="contact-section w3-animate-left" id="section_6">
                {
                    KeyTitle === 1 ?
                        <div className="bg_head">
                            <a href="/"><span className="color-white"> {I18n.t("Header.HomePage")}</span></a>
                            <i class="bi bi-chevron-double-right"></i>
                            <span>{I18n.t("Service.Service")}</span>
                        </div>
                        :
                        <></>
                }
                <div class="cta-section pd-30 section-bg hiddenxs">
                    <div class="container">
                        <div class="row justify-content-center align-items-center mt-20">
                            <div class="col-lg-5 col-12 ms-auto">
                                <h2 class="mb-0">{I18n.t("Service.ServicesWeProvide")}</h2>
                            </div>
                            <div class="col-lg-5 col-12">
                                {
                                    KeyIsHot === 1 ? <a href="/san-pham" class="custom-btn btn smoothscroll ml-20">Xem tất cả</a> : <></>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <section className="section-bg m-h-580 ServiceForm">
                    <div class="container mb-50">
                        <div class="row">
                            <div class="col-lg-12 col-12 text-center mt-20">
                                <h2>TOUR HOT </h2>
                            </div>
                            {ListData !== undefined && ListData.length > 0 &&
                                ListData.slice(0, KeyIsHot === 1 ? parseInt(NumberShowHome) : ListData.length).map((itm, Key) => {
                                    return (
                                        (
                                            <>

                                                <div class='col-lg-4 col-md-6 col-12 mb-4 mb-lg-0 min-h-550 mb-50 mt-10'>
                                                    <Link key={itm.ProductId} to={`chi-tiet-san-pham/${itm.Url}`} className="ctn-image">

                                                        <div class="custom-block-wrap text-center pointer product-container card-hover w3-animate-bottom ">
                                                            {/* <div class="tag-sale">
                                                            </div> */}
                                                            <div className="product-image">
                                                                <span class="hover-link"></span>
                                                                <a href="" class="product-link">Chi tiết</a>
                                                                <img src={itm.Image} class="custom-block-image_2 img-fluid" alt="" /></div>
                                                            <div class="custom-block product-description">
                                                                <div className="tickerhot"><span>{itm?.Attributes?.find(a => a.AttributeId === 21)?.Description}</span></div>

                                                                <div class={KeyIsHot === 0 ? "custom-block-body min-h-300 product-label" : "custom-block-body min-h-150 product-label"}>
                                                                    <h5 class="mb-3 font15" title={itm.ProductName}>{itm.ProductName}</h5>
                                                                    <div className="text-black font15">
                                                                        <div class="d-flex mb-2 ">
                                                                            <i class="fa fa-globe-asia me-2 mt-1 layoutcolor"></i>
                                                                            <span className="minimumcharacter" title={itm.Attributes.find(a => a.AttributeId === 17)?.Description}><span className="carousel-name-title">{itm.Attributes.find(a => a.AttributeId === 17)?.AttributeName}:</span> {itm.Attributes.find(a => a.AttributeId === 17)?.Description}</span>
                                                                        </div>
                                                                        <div class="d-flex mb-2">
                                                                            <i class="fa-solid fa-clock me-2 mt-1 layoutcolor"></i>
                                                                            <span className="minimumcharacter" title={itm.Attributes.find(a => a.AttributeId === 18)?.Description}><span className="carousel-name-title">{itm.Attributes.find(a => a.AttributeId === 18)?.AttributeName}:</span> {itm.Attributes.find(a => a.AttributeId === 18)?.Description}</span>
                                                                        </div>
                                                                        <div class="d-flex mb-2">
                                                                            <i class="fa-solid fa-plane me-2 mt-1 layoutcolor"></i>
                                                                            <span className="minimumcharacter" title={itm.Attributes.find(a => a.AttributeId === 19)?.Description}><span className="carousel-name-title">{itm.Attributes.find(a => a.AttributeId === 19)?.AttributeName}:</span> {itm.Attributes.find(a => a.AttributeId === 19)?.Description}</span>
                                                                        </div>
                                                                        <div class="d-flex mb-2">
                                                                            <i class="fa-solid fa-car me-2 mt-1 layoutcolor"></i>
                                                                            <span className="minimumcharacter"><span className="carousel-name-title">{itm.Attributes.find(a => a.AttributeId === 20)?.AttributeName}: </span>{itm.Attributes.find(a => a.AttributeId === 20)?.Description}</span>
                                                                        </div>
                                                                        <div class="d-flex mb-2">
                                                                           
                                                                            <span className="minimumcharacter carousel-name-title"> <i class="fa-solid fa-sack-dollar me-2 mt-1 layoutcolor"></i> Trọn gói:<span className="carousel-name-title layoutcolor" style={{ fontSize: '22px', inlineSize: '1px' }}> {FormatMoney(itm.ProductPrice)} <strong>{itm.Attributes.find(a => a.AttributeId === 22)?.Description}</strong></span> </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link >

                                                </div>

                                            </>


                                        )
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
};

